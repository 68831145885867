import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import Products from '../components/Products/Products'
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const data = [
  {
    name: "Ciężki dym",
    slug: "/organisation/dym",
    image:  <StaticImage className="features__item--img" src="../images/organisation/dym.jpg"/>,
    excerpt:
      "„TANIEC W CHMURACH” - już na stałe zawitał na polskich weselach, jako jedna z najbardziej pożądanych atrakcji.",
    linkTitle: "Poznaj ofertę",
  },
  {
    name: "Pirotechnika Sceniczna",
    slug: "/organisation/pirotechnika",
    image:  <StaticImage className="features__item--img" src="../images/organisation/pirotechnika.jpg"/>,
    excerpt:
      "Fontanny sceniczne, konfetti i inne artykuły z kategorii pirotechniki scenicznej to doskonałe sposoby na uświetnienie imprez organizowanych w pomieszczeniach zamkniętych i w plenerze.",
    linkTitle: "Poznaj ofertę",
  },
  {
    name: "Napis LOVE",
    slug: "/organisation/love",
    image:  <StaticImage className="features__item--img" src="../images/organisation/love-thumb.jpg"/>,
    excerpt:
      "Niepowtarzalne litery świetlne LOVE zapewnią wyjątkową atmosferę każdej uroczystości.",
    linkTitle: "Poznaj ofertę",
  },
  {
    name: "Oświetlenie architektoniczne",
    slug: "/organisation/oswietlenie",
    image:  <StaticImage className="features__item--img" src="../images/organisation/oswietlenie.jpg"/>,
    excerpt:
      "Każde, nawet najprostsze pomieszczenie zyskuje nowy charakter, a uroczystość nabiera niepowtarzalnego klimatu powodując zachwyt zaproszonych gości.",
    linkTitle: "Poznaj ofertę",
  },
  {
    name: "Pokaz Sztucznych Ogni",
    slug: "/organisation/ogien",
    image:  <StaticImage className="features__item--img" src="../images/organisation/ogien-thumb.jpg"/>,
    excerpt:
      "Do wyboru mają Państwo kilka pakietów zawierających różnego rodzaju pokazy świetlne.",
    linkTitle: "Poznaj ofertę",
  },
  {
    name: "Transport uczestników uroczystości",
    slug: "/organisation/transport",
    image:  <StaticImage className="features__item--img" src="../images/organisation/transport.jpg"/>,
    excerpt:
      "Pragniemy zaoferować Państwu wynajem busów (8+1) i autokarów (51  osobowych) o bardzo wysokim standardzie.",
    linkTitle: "Poznaj ofertę",
  },
  {
    name: "Foto / Video",
    slug: "/organisation/fotovideo",
    image:  <StaticImage className="features__item--img" src="../images/organisation/fotovideo.jpg"/>,
    excerpt:
      "Każde wielkie wydarzenie powinno zostać odpowiednio uwiecznione. Wychodząc na przeciw naszym klientom oferujemy usługi foto oraz wideo w atrakcyjnej cenowo ofercie wraz z zespołem muzycznym.",
    linkTitle: "Poznaj ofertę",
  },
  {
    name: "Wynajem samochodu do ślubu",
    slug: "/organisation/wynajem",
    image:  <StaticImage className="features__item--img" src="../images/organisation/wynajem-thumb.jpg"/>,
    excerpt:
      "Świadczymy profesjonalne usługi w zakresie wynajmu samochodów z kierowcą na ślub i inne uroczystości. W swej ofercie posiadamy bogaty wybór wysokiej klasy samochodów.",
    linkTitle: "Poznaj ofertę",
  },
]

const organisation = () => {
  return (
    <>
      <Seo title="Kompleksowa Organizacja" />
      <Layout>
        <SimpleBanner title="Kompleksowa Organizacja">
          <StaticImage
            className="banner__image"
            src="../images/organisation/cover.jpg"
          />
        </SimpleBanner>

        <Products products={data}/>
      </Layout>
    </>
  )
}

export default organisation
